import { useMemo } from 'react'
import { Button, SelectMultipleV2 } from '~/components/ui'
import {
  HOSPITALITY_SUBTYPES,
  INDUSTRIAL_SUBTYPES,
  LAND_SUBTYPES,
  MULTIFAMILY_SUBTYPES,
  OFFICE_SUBTYPES,
  OTHER_SUBTYPES,
  RETAIL_SUBTYPES,
} from '~/utils'

export default function MapPropertySubtypesFilter({
  propertySubtypes,
  propertyTypes,
  updateFilter,
}: {
  propertySubtypes: string[]
  propertyTypes: string[]
  updateFilter: (key: string, value: any) => void
}) {
  const groups = useMemo(() => {
    let subGroups: { value: string; label: string; group: string }[] = []

    const concatGroup = (group: string, subtypes: string[]) => {
      subGroups.push(
        ...subtypes.map((x) => ({
          value: x,
          label: x,
          group,
        }))
      )
    }

    if (propertyTypes.includes('Hospitality')) {
      concatGroup('Hospitality', HOSPITALITY_SUBTYPES)
    }
    if (propertyTypes.includes('Industrial')) {
      concatGroup('Industrial', INDUSTRIAL_SUBTYPES)
    }
    if (propertyTypes.includes('Land')) {
      concatGroup('Land', LAND_SUBTYPES)
    }
    if (propertyTypes.includes('Multifamily')) {
      concatGroup('Multifamily', MULTIFAMILY_SUBTYPES)
    }
    if (propertyTypes.includes('Office')) {
      concatGroup('Office', OFFICE_SUBTYPES)
    }
    if (propertyTypes.includes('Retail')) {
      concatGroup('Retail', RETAIL_SUBTYPES)
    }
    if (propertyTypes.includes('Other')) {
      concatGroup('Other', OTHER_SUBTYPES)
    }
    return subGroups
  }, [propertyTypes])

  return (
    <div className="space-y-2">
      <p className="text-sm">
        Secondary Use
        {propertySubtypes.length > 0 && (
          <Button
            variant="link"
            size="none"
            className="ml-2"
            onClick={() => updateFilter('propertySubtypes', undefined)}>
            (reset)
          </Button>
        )}
      </p>
      <SelectMultipleV2
        placeholder={
          groups.length === 0
            ? 'Select Primary Use to Begin...'
            : 'Select Secondary Use...'
        }
        options={groups}
        groupBy="group"
        disabled={groups.length === 0}
        value={propertySubtypes.map((v) => ({ value: v, label: v }))}
        onChange={(selected) => {
          updateFilter(
            'propertySubtypes',
            selected.map(({ value }) => value)
          )
        }}
      />
    </div>
  )
}
