import { useMemo } from 'react'
import { Button, SelectMultiple } from '~/components/ui'

export default function CountyFilter({
  countyValues,
  filterCounty,
  updateFilter,
}: {
  countyValues: string[]
  filterCounty: string[]
  updateFilter: (key: string, value: any) => void
}) {
  const countyData = useMemo(
    () =>
      countyValues.map((county) => {
        return { label: county, value: county }
      }),
    [countyValues]
  )
  const selectedCounties = useMemo(
    () => countyData.filter(({ value }) => filterCounty.includes(value)),
    [filterCounty, countyData]
  )
  return (
    <div className="space-y-2">
      <p className="text-sm">
        County
        {selectedCounties.length > 0 && (
          <Button
            variant="link"
            size="none"
            className="ml-2"
            onClick={() => updateFilter('counties', undefined)}>
            (reset)
          </Button>
        )}
      </p>
      <SelectMultiple
        placeholder="Search counties to filter..."
        options={countyData}
        selected={selectedCounties}
        // hideEmptySearchResults={true}
        setSelected={(selected) => {
          updateFilter(
            'counties',
            selected.map(({ value }) => value)
          )
        }}
      />
    </div>
  )
}
