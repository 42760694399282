import { useQuery } from '@tanstack/react-query'
import { useMemo } from 'react'
import { MapFiltersType } from '~/atoms'
import {
  MapCityFilter,
  MapCountyFilter,
  MapPostalCodeFilter,
  MapSubmarketsFilter,
  MapZoningFilter,
} from '.'

async function getLocationFilters(find: {}) {
  const response = await fetch('/api/filters', {
    method: 'POST',
    body: JSON.stringify(find),
  })
  const result = await response.json()
  if (!result.success) {
    console.error('Error getting location filters', result.error)
    throw new Error(`There was a problem fetching results: ${result.error}`)
  }
  return result
}

export default function LocationFilters({
  filters,
  updateFilter,
}: {
  filters: MapFiltersType
  updateFilter: (key: string, value: string[]) => void
}) {
  // Loads all location filters from organization in the api endpoint
  const filtersQuery = useQuery({
    queryKey: ['location-filters', 'disable-cache'],
    queryFn: () => getLocationFilters({}),
    refetchOnWindowFocus: false,
    refetchOnMount: false,
  })

  const zoningValues = useMemo(
    () => filtersQuery.data?.zoning || [],
    [filtersQuery.data]
  )

  const cityValues = useMemo(
    () => filtersQuery.data?.cities || [],
    [filtersQuery.data]
  )

  const countyValues = useMemo(
    () => filtersQuery.data?.counties || [],
    [filtersQuery.data]
  )

  const postalCodeValues = useMemo(
    () => filtersQuery.data?.postalCodes || [],
    [filtersQuery.data]
  )

  return (
    <>
      <p className="font-semibold">Location</p>
      {/* <p className="!mt-1 text-sm italic">
        Location filter values will change based on the map bounds
      </p> */}
      <MapZoningFilter
        zoningValues={zoningValues}
        filterZoning={filters.zoning || []}
        updateFilter={updateFilter}
      />

      <MapSubmarketsFilter
        submarkets={filters.submarkets || []}
        updateFilter={updateFilter}
      />

      <MapCityFilter
        cityValues={cityValues}
        filterCity={filters.cities || []}
        updateFilter={updateFilter}
      />

      <MapCountyFilter
        countyValues={countyValues}
        filterCounty={filters.counties || []}
        updateFilter={updateFilter}
      />

      <MapPostalCodeFilter
        postalCodeValues={postalCodeValues}
        filterPostalCode={filters.postalCodes || []}
        updateFilter={updateFilter}
      />
    </>
  )
}
