import { useMemo } from 'react'
import { Button, SelectMultiple } from '~/components/ui'

export default function CityFilter({
  cityValues,
  filterCity,
  updateFilter,
}: {
  cityValues: string[]
  filterCity: string[]
  updateFilter: (key: string, value: any) => void
}) {
  const cityData = useMemo(
    () =>
      cityValues.map((city) => {
        return { label: city, value: city }
      }),
    [cityValues]
  )
  const selectedCities = useMemo(
    () => cityData.filter(({ value }) => filterCity.includes(value)),
    [filterCity, cityData]
  )
  return (
    <div className="space-y-2">
      <p className="text-sm">
        City
        {selectedCities.length > 0 && (
          <Button
            variant="link"
            size="none"
            className="ml-2"
            onClick={() => updateFilter('cities', undefined)}>
            (reset)
          </Button>
        )}
      </p>
      <SelectMultiple
        placeholder="Search cities..."
        options={cityData}
        selected={selectedCities}
        // hideEmptySearchResults={true}
        setSelected={(selected) => {
          updateFilter(
            'cities',
            selected.map(({ value }) => value)
          )
        }}
      />
    </div>
  )
}
