import { withZod } from '@remix-validated-form/with-zod'
import { MultiPolygon } from 'geojson'
import { z } from 'zod'
import { zfd } from 'zod-form-data'
import { fmtNumberAbbr } from '~/utils'
import { ModelCompany } from './company'
import type { ModelOrganization } from './organization'
import type { ModelUser, User } from './user'
import { regexEmail, regexPhone, type ExternalLink } from './utils'

export type ListingBroker = Pick<
  User,
  | '_id'
  | 'slug'
  | 'tid'
  // | 'company'
  | 'firstName'
  | 'middleName'
  | 'lastName'
  | 'suffix'
  | 'email'
  | 'meta'
> & { status?: string } & { company: ModelCompany }

export type TransactionBroker = Pick<
  User,
  '_id' | 'tid' | 'slug' | 'firstName' | 'lastName' | 'email'
> & { company: ModelCompany }

export type CoordinateLocation = {
  coordinates: number[]
  type: string
}

export type Property = {
  address?: string
  address2?: string
  city?: string
  stateAbbr?: string
  postalCode?: string
  county: string
  country: string
  msa?: string
  latitude: number
  longitude: number
  placeId?: string
  parcelApn?: string
  fipsCode?: string
}

export type ParcelData = {
  _id: string
  id: string
  acres: number
  acres_calculated: number
  address: string
  building_sf: any
  city: any
  county: string
  county_fip: number
  elevation: number
  flood_zone: any
  flood_zone_subtype: any
  floors: any
  geometry: MultiPolygon
  land_use_code: string
  land_use_description: any
  latitude: number
  legal_description: string
  location: CoordinateLocation
  longitude: number
  market_value_building: number
  market_value_land: number
  market_value_total: number
  number_of_buildings: number
  owner: string
  owner_address: string
  owner_address_2: string
  parcel_id: string
  sale_price: any
  state_abbr: string
  transaction_date: any
  updated: string
  year_built: any
  zip_code: any
  zoning: string
  zoning_description: string
}

export type ListingLeaseSpace = {
  _id: string
  created: string
  modified: string
  name: string
  //floor?
  minRate?: number
  maxRate?: number
  rateType?: string
  minAvailableSf?: number
  maxAvailableSf?: number
  acres?: number
  sublease?: boolean | string
  available?: boolean | string
  tenantName?: string
  description?: string
}

export type ListingMeta = {
  description?: string
  highlights?: string
  notes?: string // internal

  // Sale
  price?: number
  investment?: boolean | string
  capRate?: number
  grossIncome?: number
  netOperatingIncome?: number
  totalExpenses?: number
  averageOccupancyRate?: number
  tenant?: string
  formerTenant?: string
  saleTerms?: string
  upForAuction?: boolean | string
  auctionType?: string
  auctionReserveAmount?: number
  auctionLicenseNumber?: number
  sealedBids?: boolean | string
  callForOffers?: boolean | string
  shortSale?: boolean | string

  // Lease
  structure?: string
  expenses?: number
  expensesType?: string
  taxYearActual?: number
  taxYearBase?: number
  signage?: string
  commission?: number
  commissionSplit?: boolean | string
  listingCommission?: number
  outsideCommission?: number
  showingInstructions?: string
  shortTermLease?: boolean | string
  doNotDisturbTenant?: boolean | string
  // operatingExpenses?: number
  // camExpenses?: number
  rentEscalators?: string
  rentConcessions?: number
  itAllowance?: number
  movingAllowance?: number
  existingLeaseBuyoutAllowance?: number
  equityLease?: boolean | string

  // Property
  acres?: number
  zoning?: string
  zoningDescription?: string
  submarket?: string
  submarketType?: string
  nearestMsa?: string
  taxIdNumber?: string
  legalDescription?: string
  propertyUseType?: string
  propertyStatus?: string
  vehiclesPerDay?: number
  frontage?: number
  lotWidth?: number
  lotDepth?: number
  buildToSuit?: boolean | string
  plannedDevelopment?: boolean | string
  inFloodPlain?: boolean | string
  easements?: string
  // easementDescription?: string
  inOpportunityZone?: boolean | string

  // Office

  // Retail
  hasDriveThru?: boolean | string

  // Multifamily
  totalUnits?: number
  laundry?: string

  // Hospitality
  nameOfEstablishment?: string
  franchiseParentName?: string

  // Industrial
  officeSf?: number
  officeCeilingHeightMin?: number
  officeCeilingHeightMax?: number
  dockNumber?: number
  dockSize?: number
  driveInNumber?: number
  driveInSize?: number
  trailerParkingSpaces?: number
  ceilingHeightMin?: number
  ceilingHeightMax?: number
  clearHeightMin?: number
  clearHeightMax?: number
  eaveHeight?: number
  columnSpacing?: string
  overheadCranes?: number
  lighting?: string
  railDoors?: number
  mezzanineSf?: number
  mezzanineHvac?: boolean | string
  warehouseSf?: number
  warehouseHvac?: boolean | string
  freightDockNumber?: number
  freightDockSize?: number
  yard?: boolean | string
  yardFenced?: boolean | string
  yardPaved?: boolean | string

  //Land
  landSplitsAvailable?: boolean | string
  adjacentParcelsAvailable?: boolean | string
  wetlands?: boolean | string
  waterfront?: boolean | string
  topography?: string
  soilType?: string

  //Building Related
  buildingName?: string
  businessPark?: string
  buildingClass?: string
  leedCertified?: boolean | string
  energyStarCertified?: boolean | string
  buildingSf?: number
  minDivisible?: number
  maxContiguous?: number
  numberOfBuildings?: number
  floors?: number
  yearBuilt?: number
  yearRenovated?: number
  roofType?: string
  constructionSiding?: string
  constructionType?: string
  parkingRatio?: string
  parkingType?: string
  parkingSpaces?: number
  ceilingHeight?: number
  passengerElevators?: number
  freightElevators?: number
  sprinklers?: string
  heatType?: string
  heatSource?: string
  airConditioning?: string
  internetAccess?: string
  rentableSf?: number
  usableSf?: number
  coreFactor?: number
  tenancy?: string
  typicalFloorSf?: number
  propertyCondition?: string

  //Transit
  nearestInterstate?: string
  distanceToInterstate?: number
  nearestHighway?: string
  distanceToHighway?: number
  nearestAirport?: string
  distanceToAirport?: number
  nearestPort?: string
  distanceToPort?: number

  //Utilities
  power?: boolean | string
  powerProvider?: string
  powerProviderType?: string
  powerAmps?: string
  powerVolts?: string
  powerPhase?: string
  water?: boolean | string
  waterProvider?: string
  waterProviderType?: string
  waterLineSize?: number
  waterCapacity?: number
  sewer?: boolean | string
  sewerProvider?: string
  sewerProviderType?: string
  sewerLineSize?: number
  sewerCapacity?: number
  naturalGas?: boolean | string
  naturalGasProvider?: string
  naturalGasProviderType?: string
  rail?: boolean | string
  railProvider?: string
  railType?: string
  fiberOptic?: boolean | string
  fiberOpticProvider?: string

  // Appraiser
  apprTransactedDate?: string
  apprLeaseLength?: number
  apprExpectedOccupancy?: string
  apprComments?: string
  apprBuyerTenantType?: string
  apprBuyerTenantName?: string
  apprSellerLandlordType?: string
  apprSellerLandlordName?: string
  apprListingBrokerName?: string
  apprListingBrokerEmail?: string
  apprListingBrokerPhone?: string
  apprListingBrokerCompany?: string
  apprSellingBrokerName?: string
  apprSellingBrokerEmail?: string
  apprSellingBrokerPhone?: string
  apprSellingBrokerCompany?: string
}

export type ListingImage = {
  _id: string
  bacId?: string
  url: string
  thumbUrl: string
}

export type ListingFile = {
  _id: string
  bacId?: string
  url: string
  title?: string
  thumbUrl?: string
  requireEmail?: string | boolean
}

export type ListingStreetView = {
  pano: string
  heading: number
  pitch: number
  fov: number
  url?: string
}

export type ListingFilters = {
  price?: number
  capRate?: number
  buildingSf?: number
  minSf?: number
  maxSf?: number
  minRate?: number
  maxRate?: number
  // minAvailableSf?: number
  // maxAvailableSf?: number
  minAcres?: number
  maxAcres?: number
  spaces?: number
  rateLabel?: string
  rateLabelAbbr?: string

  minSfHistoric?: number
  maxSfHistoric?: number
  minRateHistoric?: number
  maxRateHistoric?: number
  minAcresHistoric?: number
  maxAcresHistoric?: number
  spacesHistoric?: number
}

export type ListingTransaction = {
  _id: string
  created: string
  modified: string
  date: string
  publicFields?: string[]
  confidential?: boolean | string
  propertyType?: string
  sale?: {
    price?: number
    buildingSf?: number
    acres?: number
    terms?: string
  }
  lease?: {
    rate?: number
    totalLeaseValue?: number
    rateType?: string
    structure?: string
    footage?: number
    acres?: number
    tenantName?: string
    startDate?: string
    termInMonths?: number
    space: {
      _id: string
      name?: string
    }
  }
  notes?: string
  listingBrokers?: TransactionBroker[]
  tenantRepBrokers?: TransactionBroker[]
  noTenantRep?: boolean | string
  externalTenantRep?: boolean | string
  externalBroker?: {
    firstName: string
    lastName: string
    email: string
    phone: string
  }
}

export type Listing = {
  _id: string
  tid: string
  bacId?: string
  created: string
  modified: string
  organization: ModelOrganization
  user: ModelUser
  slug: string
  name: string
  status: string
  type: string
  tags?: string
  propertyTypes: string[]
  propertySubtypes?: string[]

  availableDate?: string
  expirationDate?: string
  offMarketDate?: string
  activeDate?: string
  listDate?: string
  deletedDate?: string

  company: ModelCompany
  brokers: ListingBroker[]
  location: CoordinateLocation
  property: Property
  leaseSpaces?: ListingLeaseSpace[]
  meta: ListingMeta
  links?: ExternalLink[]
  images?: ListingImage[]
  files?: ListingFile[]
  streetView?: ListingStreetView
  transactions?: ListingTransaction[]
  internal?: {}
  // TODO Make filters non-optional
  filters?: ListingFilters
}

type MetaFormats = 'currency' | 'number'

export type MetaFieldConfig = {
  zod?: z.ZodType
  label?: string
  level?: 'basic' | 'advanced'
  format?:
    | MetaFormats
    | ((
        v: any,
        meta: {
          [key: string]: any
        },
        valuesOnly: boolean
      ) => string | null | undefined)
  prefix?: string
  suffix?: string
  render?: boolean
  generated?: boolean
}

export const META_FIELDS: { [key: string]: MetaFieldConfig } = {
  description: { zod: zfd.text(z.string().optional()), render: false },
  highlights: { zod: zfd.text(z.string().optional()), render: false },
  notes: { zod: zfd.text(z.string().optional()), render: false },

  // Sale
  price: {
    zod: zfd.numeric(z.number().nonnegative().optional()),
    label: 'Sale Price',
    format: 'currency',
  },
  pricePerSf: {
    label: 'Price Per SF',
    generated: true,
    format: (_, meta, valuesOnly) => {
      if (meta.price && meta.buildingSf) {
        return valuesOnly
          ? `${fmtNumberAbbr(meta.price / meta.buildingSf)}`
          : `$${fmtNumberAbbr(meta.price / meta.buildingSf)}/SF`
      }
      return null
    },
  },
  // pricePerAcre: {
  //   label: 'Price Per Acre',
  //   generated: true,
  //   format: (_, meta) => {
  //     if (meta.price && meta.acres && !meta.buildingSf) {
  //       return `$${fmtNumberAbbr(meta.price / meta.acres)}/ac`
  //     }
  //     return null
  //   },
  // },
  investment: { zod: zfd.checkbox().optional() },
  capRate: {
    zod: zfd.numeric(z.number().nonnegative().optional()),
    suffix: '%',
  },
  grossIncome: {
    zod: zfd.numeric(z.number().nonnegative().optional()),
    format: 'currency',
  },
  netOperatingIncome: {
    zod: zfd.numeric(z.number().nonnegative().optional()),
    format: 'currency',
  },
  totalExpenses: {
    zod: zfd.numeric(z.number().nonnegative().optional()),
    format: 'currency',
  },
  buildingSf: {
    zod: zfd.numeric(z.number().nonnegative().optional()),
    label: 'Building Size',
    suffix: 'SF',
  },
  acres: {
    zod: zfd.numeric(z.number().nonnegative().optional()),
    // format: 'number',
    format: (v) => {
      return v
        ? v
            .toLocaleString(undefined, { minimumFractionDigits: 4 })
            .replace(/(\.\d*?[1-9])0+$/, '$1')
            .replace('.0000', '')
        : ''
    },
    suffix: 'ac',
  },
  buildToSuit: { zod: zfd.checkbox().optional() },
  zoning: { zod: zfd.text(z.string().optional()) },
  zoningDescription: { zod: zfd.text(z.string().optional()) },

  tenant: { zod: zfd.text(z.string().optional()) },
  formerTenant: { zod: zfd.text(z.string().optional()) },
  saleTerms: { zod: zfd.text(z.string().optional()), level: 'advanced' },
  upForAuction: { zod: zfd.checkbox().optional(), level: 'advanced' },
  auctionType: { zod: zfd.text(z.string().optional()), level: 'advanced' },
  auctionReserveAmount: {
    zod: zfd.numeric(z.number().nonnegative().optional()),
    level: 'advanced',
  },
  auctionLicenseNumber: {
    zod: zfd.text(z.string().optional()),
    level: 'advanced',
  },
  sealedBids: { zod: zfd.checkbox().optional(), level: 'advanced' },
  callForOffers: { zod: zfd.checkbox().optional(), level: 'advanced' },
  shortSale: { zod: zfd.checkbox().optional(), level: 'advanced' },

  // Lease
  structure: { zod: zfd.text(z.string().optional()) },
  expenses: {
    zod: zfd.numeric(z.number().nonnegative().optional()),
    format: 'currency',
  },
  expensesType: { zod: zfd.text(z.string().optional()) },
  taxYearActual: { zod: zfd.numeric(z.number().nonnegative().optional()) },
  taxYearBase: { zod: zfd.numeric(z.number().nonnegative().optional()) },
  signage: { zod: zfd.text(z.string().optional()) },
  commission: {
    render: false,
    zod: zfd.numeric(z.number().nonnegative().optional()),
    suffix: '%',
    // format: (value, meta) => {
    //   if (meta.commissionSplit) {
    //     if (meta.listingCommission && meta.outsideCommission) {
    //       return `${value}% split ${meta.listingCommission}% - ${meta.outsideCommission}%`
    //     }
    //     return `${value}% split`
    //   }
    //   return `${value}%`
    // },
  },
  commissionSplit: { zod: zfd.checkbox().optional(), render: false },
  listingCommission: {
    zod: zfd.numeric(z.number().nonnegative().optional()),
    suffix: '%',
    render: false,
  },
  outsideCommission: {
    zod: zfd.numeric(z.number().nonnegative().optional()),
    suffix: '%',
    render: false,
  },
  doNotDisturbTenant: { zod: zfd.checkbox().optional(), level: 'advanced' },
  showingInstructions: { zod: zfd.text(z.string().optional()) },
  shortTermLease: { zod: zfd.checkbox().optional(), level: 'advanced' },
  // operatingExpenses: {
  //   zod: zfd.numeric(z.number().nonnegative().optional()),
  //   level: 'advanced',
  // },
  // camExpenses: {
  //   zod: zfd.numeric(z.number().nonnegative().optional()),
  //   level: 'advanced',
  // },
  rentEscalators: { zod: zfd.text(z.string().optional()), level: 'advanced' },
  rentConcessions: {
    zod: zfd.numeric(z.number().nonnegative().optional()),
    level: 'advanced',
  },
  itAllowance: {
    zod: zfd.numeric(z.number().nonnegative().optional()),
    level: 'advanced',
  },
  movingAllowance: {
    zod: zfd.numeric(z.number().nonnegative().optional()),
    level: 'advanced',
  },
  existingLeaseBuyoutAllowance: {
    zod: zfd.numeric(z.number().nonnegative().optional()),
    level: 'advanced',
  },
  equityLease: { zod: zfd.checkbox().optional(), level: 'advanced' },

  // Building Details
  buildingName: { zod: zfd.text(z.string().optional()) },
  businessPark: { zod: zfd.text(z.string().optional()), level: 'advanced' },
  buildingClass: { zod: zfd.text(z.string().optional()) },
  leedCertified: { zod: zfd.checkbox().optional() },
  energyStarCertified: { zod: zfd.checkbox().optional() },
  minDivisible: {
    zod: zfd.numeric(z.number().nonnegative().optional()),
    format: 'number',
  },
  maxContiguous: {
    zod: zfd.numeric(z.number().nonnegative().optional()),
    format: 'number',
  },
  numberOfBuildings: {
    zod: zfd.numeric(z.number().nonnegative().optional()),
    level: 'advanced',
  },
  floors: { zod: zfd.numeric(z.number().nonnegative().optional()) },
  yearBuilt: { zod: zfd.numeric(z.number().nonnegative().optional()) },
  yearRenovated: {
    zod: zfd.numeric(z.number().nonnegative().optional()),
    level: 'advanced',
  },
  roofType: { zod: zfd.text(z.string().optional()), level: 'advanced' },
  constructionSiding: {
    zod: zfd.text(z.string().optional()),
    level: 'advanced',
  },
  constructionType: {
    zod: zfd.text(z.string().optional()),
    level: 'advanced',
  },
  parkingRatio: {
    zod: zfd.text(z.string().optional()),
    format: (v: string) => {
      if (v.includes('/') || v.includes('1000') || v.includes('1,000')) return v
      return `${v} / 1000`
    },
  },
  parkingType: { zod: zfd.text(z.string().optional()), level: 'advanced' },
  parkingSpaces: { zod: zfd.numeric(z.number().nonnegative().optional()) },
  ceilingHeight: {
    zod: zfd.numeric(z.number().nonnegative().optional()),
    level: 'advanced',
  },
  passengerElevators: {
    zod: zfd.numeric(z.number().nonnegative().optional()),
    level: 'advanced',
  },
  freightElevators: {
    zod: zfd.numeric(z.number().nonnegative().optional()),
    level: 'advanced',
  },
  sprinklers: { zod: zfd.text(z.string().optional()), level: 'advanced' },
  heatType: { zod: zfd.text(z.string().optional()), level: 'advanced' },
  heatSource: { zod: zfd.text(z.string().optional()), level: 'advanced' },
  airConditioning: { zod: zfd.text(z.string().optional()), level: 'advanced' },
  internetAccess: { zod: zfd.text(z.string().optional()), level: 'advanced' },
  rentableSf: {
    zod: zfd.numeric(z.number().nonnegative().optional()),
    level: 'advanced',
    label: 'Rentable SF',
    format: 'number',
  },
  usableSf: {
    zod: zfd.numeric(z.number().nonnegative().optional()),
    level: 'advanced',
    label: 'Usable SF',
    suffix: 'SF',
  },
  coreFactor: {
    zod: zfd.numeric(z.number().nonnegative().optional()),
    level: 'advanced',
  },
  tenancy: { zod: zfd.text(z.string().optional()), level: 'advanced' },
  typicalFloorSf: {
    zod: zfd.numeric(z.number().nonnegative().optional()),
    level: 'advanced',
    suffix: 'SF',
  },
  propertyCondition: {
    zod: zfd.text(z.string().optional()),
    level: 'advanced',
  },
  submarket: { zod: zfd.text(z.string().optional()) },
  submarketType: { zod: zfd.text(z.string().optional()) },
  nearestMsa: { zod: zfd.text(z.string().optional()), label: 'Nearest MSA' },
  taxIdNumber: { zod: zfd.text(z.string().optional()), label: 'Tax ID Number' },
  legalDescription: { zod: zfd.text(z.string().optional()) },
  propertyUseType: { zod: zfd.text(z.string().optional()) },
  propertyStatus: { zod: zfd.text(z.string().optional()) },
  vehiclesPerDay: {
    zod: zfd.numeric(z.number().nonnegative().optional()),
    suffix: 'VPD',
    format: 'number',
  },
  averageOccupancyRate: {
    zod: zfd.numeric(z.number().nonnegative().optional()),
    level: 'advanced',
  },
  frontage: {
    zod: zfd.numeric(z.number().nonnegative().optional()),
    level: 'advanced',
    label: 'Lot Frontage',
    suffix: 'ft',
    format: 'number',
  },
  lotWidth: {
    zod: zfd.numeric(z.number().nonnegative().optional()),
    level: 'advanced',
    label: 'Lot Width',
    suffix: 'ft',
  },
  lotDepth: {
    zod: zfd.numeric(z.number().nonnegative().optional()),
    level: 'advanced',
    label: 'Lot Depth',
    suffix: 'ft',
  },
  inOpportunityZone: { zod: zfd.checkbox().optional(), level: 'advanced' },
  easements: { zod: zfd.text(z.string().optional()), level: 'advanced' },
  plannedDevelopment: { zod: zfd.checkbox().optional(), level: 'advanced' },
  inFloodPlain: { zod: zfd.checkbox().optional(), level: 'advanced' },

  //Office

  //Retail
  hasDriveThru: { zod: zfd.checkbox().optional() },

  //Multifamily
  totalUnits: { zod: zfd.numeric(z.number().nonnegative().optional()) },
  laundry: { zod: zfd.text(z.string().optional()) },

  //Hospitality
  nameOfEstablishment: { zod: zfd.text(z.string().optional()) },
  franchiseParentName: { zod: zfd.text(z.string().optional()) },

  // Industrial
  officeSf: {
    zod: zfd.numeric(z.number().nonnegative().optional()),
    label: 'Office SF',
    suffix: 'SF',
  },
  officeCeilingHeightMin: {
    zod: zfd.numeric(z.number().nonnegative().optional()),
  },
  officeCeilingHeightMax: {
    zod: zfd.numeric(z.number().nonnegative().optional()),
  },
  dockNumber: { zod: zfd.numeric(z.number().nonnegative().optional()) },
  dockSize: { zod: zfd.text(z.string().optional()) },
  driveInNumber: { zod: zfd.numeric(z.number().nonnegative().optional()) },
  driveInSize: { zod: zfd.text(z.string().optional()) },
  trailerParkingSpaces: {
    zod: zfd.numeric(z.number().nonnegative().optional()),
  },
  ceilingHeightMin: {
    zod: zfd.numeric(z.number().nonnegative().optional()),
    label: 'Ceiling Height',
    format: (_, meta) => {
      return [meta.ceilingHeightMin, meta.ceilingHeightMax].join(' - ')
    },
  },
  ceilingHeightMax: {
    zod: zfd.numeric(z.number().nonnegative().optional()),
    render: false,
  },
  clearHeightMin: {
    zod: zfd.numeric(z.number().nonnegative().optional()),
    label: 'Clear Height',
    format: (_, meta) => {
      return [meta.clearHeightMin, meta.clearHeightMax].join(' - ')
    },
  },
  clearHeightMax: {
    zod: zfd.numeric(z.number().nonnegative().optional()),
    render: false,
  },
  eaveHeight: { zod: zfd.numeric(z.number().nonnegative().optional()) },
  columnSpacing: { zod: zfd.text(z.string().optional()) },
  overheadCranes: { zod: zfd.numeric(z.number().nonnegative().optional()) }, // overheadCranes to craneNumber
  lighting: { zod: zfd.text(z.string().optional()) },
  railDoors: { zod: zfd.numeric(z.number().nonnegative().optional()) },
  mezzanineSf: {
    zod: zfd.numeric(z.number().nonnegative().optional()),
    level: 'advanced',
    label: 'Mezzanine SF',
    suffix: 'SF',
  },
  mezzanineHvac: { zod: zfd.checkbox().optional(), level: 'advanced' },
  warehouseSf: {
    zod: zfd.numeric(z.number().nonnegative().optional()),
    level: 'advanced',
    label: 'Warehouse SF',
    suffix: 'SF',
  },
  warehouseHvac: { zod: zfd.checkbox().optional(), level: 'advanced' },
  freightDockNumber: {
    zod: zfd.numeric(z.number().nonnegative().optional()),
    level: 'advanced',
  },
  freightDockSize: {
    zod: zfd.numeric(z.number().nonnegative().optional()),
    level: 'advanced',
  },
  yard: {
    zod: zfd.checkbox().optional(),
    level: 'advanced',
    format: (yard, meta) => {
      if (meta.yardFenced && meta.yardPaved) {
        return 'Fenced, Paved'
      } else if (meta.yardFenced) {
        return 'Fenced'
      } else if (meta.yardPaved) {
        return 'Paved'
      }
      return yard
    },
  },
  yardFenced: {
    zod: zfd.checkbox().optional(),
    level: 'advanced',
    render: false,
  },
  yardPaved: {
    zod: zfd.checkbox().optional(),
    level: 'advanced',
    render: false,
  },

  //Land
  landSplitsAvailable: { zod: zfd.checkbox().optional() },
  adjacentParcelsAvailable: { zod: zfd.checkbox().optional() },
  wetlands: { zod: zfd.checkbox().optional() },
  waterfront: { zod: zfd.checkbox().optional() },
  topography: { zod: zfd.text(z.string().optional()) },
  soilType: { zod: zfd.text(z.string().optional()) },

  //Transit
  nearestInterstate: {
    zod: zfd.text(z.string().optional()),
    format: (nearestInterstate, meta) => {
      return nearestInterstate && meta.distanceToInterstate
        ? `${nearestInterstate} - ${meta.distanceToInterstate} mi`
        : nearestInterstate
    },
  },
  distanceToInterstate: {
    zod: zfd.numeric(z.number().nonnegative().optional()),
    format: (distanceToInterstate, meta) => {
      return meta.nearestInterstate ? null : `${distanceToInterstate} mi`
    },
  },
  nearestHighway: {
    zod: zfd.text(z.string().optional()),
    format: (nearestHighway, meta) => {
      return nearestHighway && meta.distanceToHighway
        ? `${nearestHighway} - ${meta.distanceToHighway} mi`
        : nearestHighway
    },
  },
  distanceToHighway: {
    zod: zfd.numeric(z.number().nonnegative().optional()),
    format: (distanceToHighway, meta) => {
      return meta.nearestHighway ? null : `${distanceToHighway} mi`
    },
  },
  nearestAirport: {
    zod: zfd.text(z.string().optional()),
    format: (nearestAirport, meta) => {
      return nearestAirport && meta.distanceToAirport
        ? `${nearestAirport} - ${meta.distanceToAirport} mi`
        : nearestAirport
    },
  },
  distanceToAirport: {
    zod: zfd.numeric(z.number().nonnegative().optional()),
    format: (distanceToAirport, meta) => {
      return meta.nearestAirport ? null : `${distanceToAirport} mi`
    },
  },
  nearestPort: {
    zod: zfd.text(z.string().optional()),
    format: (nearestPort, meta) => {
      return nearestPort && meta.distanceToPort
        ? `${nearestPort} - ${meta.distanceToPort} mi`
        : nearestPort
    },
  },
  distanceToPort: {
    zod: zfd.numeric(z.number().nonnegative().optional()),
    format: (distanceToPort, meta) => {
      return meta.nearestPort ? null : `${distanceToPort} mi`
    },
  },

  //Utilities
  power: {
    zod: zfd.checkbox().optional(),
    render: false,
  },
  powerProvider: {
    zod: zfd.text(z.string().optional()),
    format(powerProvider, meta) {
      return powerProvider && meta.powerProviderType
        ? `${meta.powerProviderType} - ${powerProvider}`
        : powerProvider
    },
  },
  powerProviderType: { zod: zfd.text(z.string().optional()), render: false },
  powerAmps: {
    zod: zfd.text(z.string().optional()),
    suffix: 'A',
  },
  powerVolts: {
    zod: zfd.text(z.string().optional()),
    suffix: 'V',
  },
  powerPhase: { zod: zfd.text(z.string().optional()) },
  water: {
    zod: zfd.checkbox().optional(),
    render: false,
  },
  waterProvider: {
    zod: zfd.text(z.string().optional()),
    format: (waterProvider, meta) => {
      return waterProvider && meta.waterProviderType
        ? `${meta.waterProviderType} - ${waterProvider}`
        : waterProvider
    },
  },
  waterProviderType: { zod: zfd.text(z.string().optional()), render: false },
  waterLineSize: { zod: zfd.numeric(z.number().nonnegative().optional()) },
  waterCapacity: { zod: zfd.numeric(z.number().nonnegative().optional()) },
  sewer: { zod: zfd.checkbox().optional(), render: false },
  sewerProvider: {
    zod: zfd.text(z.string().optional()),
    format: (sewerProvider, meta) => {
      return sewerProvider && meta.sewerProviderType
        ? `${meta.sewerProviderType} - ${sewerProvider}`
        : sewerProvider
    },
  },
  sewerProviderType: { zod: zfd.text(z.string().optional()), render: false },
  sewerLineSize: { zod: zfd.numeric(z.number().nonnegative().optional()) },
  sewerCapacity: { zod: zfd.numeric(z.number().nonnegative().optional()) },
  naturalGas: { zod: zfd.checkbox().optional(), render: false },
  naturalGasProvider: {
    zod: zfd.text(z.string().optional()),
    format: (naturalGasProvider, meta) => {
      return naturalGasProvider && meta.naturalGasProviderType
        ? `${meta.naturalGasProviderType} - ${naturalGasProvider}`
        : naturalGasProvider
    },
  },
  naturalGasProviderType: {
    zod: zfd.text(z.string().optional()),
    render: false,
  },
  rail: { zod: zfd.checkbox().optional(), render: false },
  railProvider: {
    zod: zfd.text(z.string().optional()),
    format: (railProvider, meta) => {
      return railProvider && meta.railProviderType
        ? `${meta.railProviderType} - ${railProvider}`
        : railProvider
    },
  },
  railType: { zod: zfd.text(z.string().optional()), render: false },
  fiberOptic: { zod: zfd.checkbox().optional() },
  fiberOpticProvider: { zod: zfd.text(z.string().optional()) },
  fiberOpticProviderType: { zod: zfd.text(z.string().optional()) },

  // Appraiser
  apprTransactedDate: {
    zod: zfd.text(z.string().optional()),
    render: false,
    // label: 'Transacted Date',
    // format: (transactedDate) =>
    //   transactedDate ? transactedDate.split('T')[0] : undefined,
  },
  apprLeaseLength: {
    zod: zfd.numeric(z.number().nonnegative().optional()),
    label: 'Lease Length',
  },
  apprExpectedOccupancy: {
    zod: zfd.text(z.string().optional()),
    label: 'Expected Occupancy',
  },
  apprComments: {
    zod: zfd.text(z.string().optional()),
    label: 'Comments',
    render: false,
  },
  apprBuyerTenantType: {
    zod: zfd.text(z.string().optional()),
    label: 'Buyer Tenant Type',
  },
  apprBuyerTenantName: {
    zod: zfd.text(z.string().optional()),
    label: 'Buyer Tenant Name',
  },
  apprSellerLandlordType: {
    zod: zfd.text(z.string().optional()),
    label: 'Seller Landlord Type',
  },
  apprSellerLandlordName: {
    zod: zfd.text(z.string().optional()),
    label: 'Seller Landlord Name',
  },
  apprListingBrokerName: {
    zod: zfd.text(z.string().optional()),
    label: 'Listing Broker Name',
  },
  apprListingBrokerEmail: {
    zod: zfd.text(z.string().optional()),
    label: 'Listing Broker Email',
  },
  apprListingBrokerPhone: {
    zod: zfd.text(z.string().optional()),
    label: 'Listing Broker Phone',
  },
  apprListingBrokerCompany: {
    zod: zfd.text(z.string().optional()),
    label: 'Listing Broker Company',
  },
  apprSellingBrokerName: {
    zod: zfd.text(z.string().optional()),
    label: 'Selling Broker Name',
  },
  apprSellingBrokerEmail: {
    zod: zfd.text(z.string().optional()),
    label: 'Selling Broker Email',
  },
  apprSellingBrokerPhone: {
    zod: zfd.text(z.string().optional()),
    label: 'Selling Broker Phone',
  },
  apprSellingBrokerCompany: {
    zod: zfd.text(z.string().optional()),
    label: 'Selling Broker Company',
  },
}

const zodMetaObject: { [key: string]: z.ZodType } = {}
Object.keys(META_FIELDS).forEach((key) => {
  if (META_FIELDS[key].zod) {
    zodMetaObject[key] = META_FIELDS[key].zod!
  }
})

export const ListingBrokerSchema = z.object({
  _id: zfd.text(z.string()),
  slug: zfd.text(z.string()),
  tid: zfd.text(z.string()),
  status: zfd.text(z.string().optional()).optional(),
  firstName: zfd.text(z.string()),
  middleName: zfd.text(z.string().optional()),
  lastName: zfd.text(z.string()),
  email: zfd.text(z.string()),
  phone: zfd.text(z.string().optional()),
  company: z.object({
    _id: zfd.text(z.string()),
    name: zfd.text(z.string()),
    slug: zfd.text(z.string()),
    tid: zfd.text(z.string()),
  }),
  meta: z
    .object({
      mobile: zfd.text(z.string().optional()),
      office: zfd.text(z.string().optional()),
      title: zfd.text(z.string().optional()),
      memberships: z.string().optional(),
      licenseNumber: z.string().optional(),
      licenseExpiration: z.string().optional(),
      photo: z
        .object({
          _id: zfd.text(z.string()),
          url: zfd.text(z.string()),
          thumbUrl: zfd.text(z.string()),
        })
        .optional(),
    })
    .optional(),
})

const TransactionBrokerSchema = z.object({
  _id: zfd.text(z.string()),
  tid: zfd.text(z.string()),
  slug: zfd.text(z.string()),
  firstName: zfd.text(z.string()),
  lastName: zfd.text(z.string()),
  email: zfd.text(z.string()),
  company: z.object({
    _id: zfd.text(z.string()),
    name: zfd.text(z.string()),
    slug: zfd.text(z.string()),
    tid: zfd.text(z.string()),
  }),
}) satisfies z.ZodType<TransactionBroker>

export const CreateListingTransactionSchema = z
  .object({
    _id: zfd.text(z.string()),
    created: zfd.text(z.string()),
    modified: zfd.text(z.string()),
    date: z.string().min(1, 'Please select a transaction date'),
    publicFields: zfd.repeatable(z.array(z.string()).optional()),
    confidential: zfd.checkbox(),
    propertyType: z.string().min(1, 'Please select a primary use'),
    sale: z
      .object({
        price: zfd.numeric(z.number().gt(0).optional()),
        buildingSf: zfd.numeric(z.number().gt(0).optional()), // error text not working 'Please enter a building size'
        acres: zfd.numeric(z.number().gt(0).optional()),
        terms: zfd.text(z.string().optional()),
      })
      .optional(),
    lease: z
      .object({
        rate: zfd.numeric(z.number().nonnegative().optional()),
        totalLeaseValue: zfd.numeric(z.number().nonnegative().optional()),
        rateType: zfd.text(z.string().optional()),
        structure: zfd.text(z.string().optional()),
        footage: zfd.numeric(z.number().gt(0).optional()),
        acres: zfd.numeric(z.number().gt(0).optional()),
        tenantName: zfd.text(z.string().optional()),
        startDate: zfd.text(z.string().optional()),
        termInMonths: zfd.numeric(z.number().nonnegative().optional()),
        space: z.object({
          _id: zfd.text(z.string()),
          name: zfd.text(z.string().optional()),
        }),
      })
      .optional(),
    notes: zfd.text(z.string().optional()),
    listingBrokers: zfd.repeatable(
      z.array(TransactionBrokerSchema).min(1, 'Add at least one listing broker')
    ),
    tenantRepBrokers: zfd
      // .repeatable(z.array(z.custom<TransactionBroker>()))
      .repeatable(z.array(TransactionBrokerSchema))
      .optional(),
    noTenantRep: zfd.checkbox().optional(),
    externalTenantRep: zfd.checkbox().optional(),
    externalBroker: z
      .object({
        firstName: z.string().min(1, 'First name is required'),
        lastName: z.string().min(1, 'Last name is required'),
        email: z
          .string()
          .min(1, 'Email is required')
          .refine((val) => regexEmail(val), {
            message: 'Please enter a valid email',
          }),
        phone: z
          .string()
          .min(1, 'Phone number is required')
          .refine((val) => regexPhone(val), {
            message: 'Please enter a valid phone number.',
          }),
      })
      .optional(),
  })
  .refine(
    (val) => {
      return val.sale && !val.sale.buildingSf && !val.sale.acres ? false : true
    },
    {
      message: 'Enter either Building Size or Acres',
      path: ['sale.buildingSf'],
    }
  )
  .refine(
    (val) => {
      return val.lease && !val.lease.footage && !val.lease.acres ? false : true
    },
    {
      message: 'Enter either Square Footage or Acres',
      path: ['lease.footage'],
    }
  )
  .refine(
    (val) => {
      return val.lease && val.lease.rate && !val.lease.rateType ? false : true
    },
    {
      message: 'Please enter a rate type',
      path: ['lease.rateType'],
    }
  )
  .refine(
    (val) => {
      return val.lease &&
        (val.lease.rate || val.lease.totalLeaseValue) &&
        !val.lease.termInMonths
        ? false
        : true
    },
    {
      message: 'Please enter a term in months',
      path: ['lease.termInMonths'],
    }
  )
  .refine(
    (val) => {
      return val.lease?.rate && !val.lease?.structure ? false : true
    },
    {
      message: 'Please enter a lease type',
      path: ['lease.structure'],
    }
  )
  .refine(
    (val) => {
      if (val.noTenantRep || val.externalTenantRep) {
        return true
      }
      return (val.tenantRepBrokers || []).length === 0 ? false : true
    },
    {
      message: 'Select at least one Tenant Rep Broker',
      path: ['tenantRepBrokers'],
    }
  ) satisfies z.ZodType<ListingTransaction>

export const PropertySchema = z.object({
  address: zfd.text(z.string().optional()),
  address2: zfd.text(z.string().optional()),
  city: zfd.text(z.string().optional()),
  stateAbbr: zfd.text(z.string().min(1, 'State is required')),
  postalCode: zfd.text(
    z
      .string()
      .min(5, 'Postal Code should be five digits')
      .max(5, 'Use five digit postal code only')
      .optional()
  ),
  county: zfd.text(z.string().min(1, 'County is required')),
  country: zfd.text(z.string()),
  msa: zfd.text(z.string().optional()),
  latitude: zfd.numeric(
    z
      .number()
      .gte(-90, 'Enter value between -90 and 90 inclusive')
      .lte(90, 'Enter value between -90 and 90 inclusive')
  ),
  longitude: zfd.numeric(
    z
      .number()
      .gte(-180, 'Enter value between -180 and 180 inclusive')
      .lte(180, 'Enter value between -180 and 180 inclusive')
  ),
  placeId: zfd.text(z.string().optional()),
  parcelApn: zfd.text(z.string().optional()),
  fipsCode: zfd.text(z.string().optional()),
})

export const ListingTransactionSchema = z.object({
  _id: zfd.text(z.string()),
  created: zfd.text(z.string()),
  modified: zfd.text(z.string()),
  date: z.string().min(1, 'Please select a transaction date'),
  publicFields: zfd.repeatable(z.array(z.string()).optional()),
  confidential: zfd.checkbox(),
  propertyType: z.string().min(1, 'Please select a primary use').optional(),
  sale: z
    .object({
      price: zfd.numeric(z.number().gt(0).optional()),
      buildingSf: zfd.numeric(z.number().gt(0).optional()), // error text not working 'Please enter a building size'
      acres: zfd.numeric(z.number().gt(0).optional()),
      terms: zfd.text(z.string().optional()),
    })
    .optional(),
  lease: z
    .object({
      rate: zfd.numeric(z.number().nonnegative().optional()),
      totalLeaseValue: zfd.numeric(z.number().nonnegative().optional()),
      rateType: zfd.text(z.string().optional()),
      structure: zfd.text(z.string().optional()),
      footage: zfd.numeric(z.number().gt(0).optional()),
      acres: zfd.numeric(z.number().gt(0).optional()),
      tenantName: zfd.text(z.string().optional()),
      startDate: zfd.text(z.string().optional()),
      termInMonths: zfd.numeric(z.number().nonnegative().optional()),
      space: z.object({
        _id: zfd.text(z.string()),
        name: zfd.text(z.string().optional()),
      }),
    })
    .optional(),
  notes: zfd.text(z.string().optional()),
  listingBrokers: zfd
    .repeatable(
      z.array(TransactionBrokerSchema).min(1, 'Add at least one listing broker')
    )
    .optional(),
  tenantRepBrokers: zfd
    // .repeatable(z.array(z.custom<TransactionBroker>()))
    .repeatable(z.array(TransactionBrokerSchema))
    .optional(),
  noTenantRep: zfd.checkbox().optional(),
  externalTenantRep: zfd.checkbox().optional(),
  externalBroker: z
    .object({
      firstName: z.string().min(1, 'First name is required'),
      lastName: z.string().min(1, 'Last name is required'),
      email: z
        .string()
        .min(1, 'Email is required')
        .refine((val) => regexEmail(val), {
          message: 'Please enter a valid email',
        }),
      phone: z
        .string()
        .min(1, 'Phone number is required')
        .refine((val) => regexPhone(val), {
          message: 'Please enter a valid phone number.',
        }),
    })
    .optional(),
}) satisfies z.ZodType<ListingTransaction>

export const ListingSchema = z
  .object({
    _id: z.string(),
    tid: z.string(),
    bacId: z.string().optional(),
    created: z.string(),
    modified: z.string(),
    organization: z.custom<ModelOrganization>(),
    user: z.custom<ModelUser>(),
    slug: zfd.text(z.string()),
    name: zfd.text(z.string().min(1)),
    status: zfd.text(z.string()),
    type: zfd.text(z.string()),
    tags: zfd.text(z.string().optional()),
    propertyTypes: zfd.repeatable(
      z
        .array(z.string())
        .min(1, 'Add at least one Primary Use')
        .max(2, 'Maximum of two Primary Uses')
    ),
    propertySubtypes: zfd.repeatable(z.array(z.string()).optional()),
    company: z.object({
      _id: zfd.text(z.string()),
      name: zfd.text(z.string()),
      slug: zfd.text(z.string()),
      tid: zfd.text(z.string()),
    }),
    availableDate: z.string().optional(),
    expirationDate: z.string().optional(),
    offMarketDate: z.string().optional(),
    activeDate: z.string().optional(),
    listDate: z.string().optional(),
    deletedDate: z.string().optional(),
    brokers: zfd.repeatable(z.array(ListingBrokerSchema)),
    location: z.object({
      type: z.string(),
      coordinates: z.array(zfd.numeric(z.number())),
    }),
    property: PropertySchema,
    leaseSpaces: z
      .array(
        z
          .object({
            _id: zfd.text(z.string()),
            created: zfd.text(z.string()),
            modified: zfd.text(z.string()),
            name: zfd.text(z.string().optional()),
            minRate: zfd.numeric(z.number().nonnegative().optional()),
            maxRate: zfd.numeric(z.number().nonnegative().optional()),
            rateType: zfd.text(z.string().optional()),
            minAvailableSf: zfd.numeric(z.number().nonnegative().optional()),
            maxAvailableSf: zfd.numeric(z.number().nonnegative().optional()),
            acres: zfd.numeric(z.number().nonnegative().optional()),
            sublease: zfd.checkbox(),
            available: zfd.checkbox(),
            tenantName: zfd.text(z.string().optional()),
            description: zfd.text(z.string().optional()),
          })
          // .refine(
          //   (val) => {
          //     if (!val.minAvailableSf && !val.maxAvailableSf && !val.acres) {
          //       return false
          //     }
          //     return true
          //   },
          //   {
          //     message:
          //       'Set either Min Available SF and Max Available SF or Acres',
          //   }
          // )
          .refine(
            (val) => {
              if (
                val.minAvailableSf &&
                val.maxAvailableSf &&
                val.minAvailableSf > val.maxAvailableSf
              ) {
                return false
              }
              return true
            },
            {
              message: 'Max Available SF must be less than Min Available SF',
              path: ['maxAvailableSf'],
            }
          )
          .refine(
            (val) => {
              if (val.minAvailableSf && !val.maxAvailableSf) {
                return false
              } else if (val.maxAvailableSf && !val.minAvailableSf) {
                return false
              }
              return true
            },
            {
              message: 'Set both a Max and Min Available SF',
              path: ['maxAvailableSf'],
            }
          )
          .refine(
            (val) => {
              if (val.minRate && val.maxRate && val.minRate > val.maxRate) {
                return false
              }
              return true
            },
            {
              message: 'Max Rate must be less than Min Rate',
              path: ['maxRate'],
            }
          )
          .refine(
            (val) => {
              if (val.minRate && !val.maxRate) {
                return false
              } else if (val.maxRate && !val.minRate) {
                return false
              }
              return true
            },
            {
              message:
                // 'Please set both a minimum and maximum rate (if fixed rate enter the value in both fields)',
                'Set both a Max and a Min Rate',
              path: ['maxRate'],
            }
          )
      )
      .optional()
      .refine(
        (val) => {
          if (val && val.length > 1) {
            const names = val.filter((v) => !v.name)
            return names.length === 0
          }
          return true
        },
        {
          message: 'Define a Name for each space if more than one space',
        }
      ),
    meta: z
      .object(zodMetaObject)
      .refine(
        (val) => {
          if (
            val.ceilingHeightMin &&
            val.ceilingHeightMax &&
            val.ceilingHeightMin > val.ceilingHeightMax
          ) {
            return false
          }
          return true
        },
        {
          message: 'Ceiling Height minimum value must be less than maximum.',
          path: ['ceilingHeightMin'],
        }
      )
      .refine(
        (val) => {
          if (
            val.officeCeilingHeightMin &&
            val.officeCeilingHeightMax &&
            val.officeCeilingHeightMin > val.officeCeilingHeightMax
          ) {
            return false
          }
          return true
        },
        {
          message: 'Ceiling Height minimum value must be less than maximum.',
          path: ['officeCeilingHeightMin'],
        }
      )
      .refine(
        (val) => {
          if (
            val.clearHeightMin &&
            val.clearHeightMax &&
            val.clearHeightMin > val.clearHeightMax
          ) {
            return false
          }
          return true
        },
        {
          message: 'Clear Height minimum value must be less than maximum.',
          path: ['clearHeightMin'],
        }
      ),
    links: z
      .array(
        z.object({
          name: zfd.text(z.string()),
          url: zfd.text(z.string()),
          type: zfd.text(z.string()).optional(),
        })
      )
      .optional()
      .default([]),
    images: z
      .array(
        z.object({
          _id: zfd.text(z.string()),
          bacId: zfd.text(z.string()).optional(),
          url: zfd.text(z.string()),
          thumbUrl: zfd.text(z.string()),
        })
      )
      .optional()
      .default([]),
    files: z
      .array(
        z.object({
          _id: zfd.text(z.string()),
          bacId: zfd.text(z.string()).optional(),
          url: zfd.text(z.string()),
          title: zfd.text(z.string()).optional(),
          requireEmail: zfd.checkbox().optional(),
        })
      )
      .optional()
      .default([]),

    streetView: z
      .object({
        pano: zfd.text(z.string()),
        heading: zfd.numeric(z.number()),
        pitch: zfd.numeric(z.number()),
        fov: zfd.numeric(z.number()),
        url: zfd.numeric(z.string().optional()),
      })
      .optional(),

    transactions: z.array(ListingTransactionSchema).optional().default([]),
  })
  .refine(
    (val) => {
      return true
      if (val.type !== 'sale' && val.meta.structure) {
        return true
      } else {
        return false
      }
    },
    {
      message: 'Please specify the lease type',
      path: ['meta.structure'],
    }
  ) satisfies z.ZodType<Omit<Listing, 'filters'>>

export const listingBrokerValidator = withZod(ListingBrokerSchema)
export const createListingTransactionValidator = withZod(
  CreateListingTransactionSchema
)
