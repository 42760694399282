import { Link, useNavigate } from '@remix-run/react'
import algoliasearch from 'algoliasearch'
import { useAtomValue, useSetAtom } from 'jotai'
import { LucideExternalLink } from 'lucide-react'
import { useCallback, useEffect, useState } from 'react'
import { useMap } from 'react-map-gl'
import { aOrganization, aSelectedListing, aUser } from '~/atoms'
import { Badge, Button, Command } from '~/components/ui'
import { useDebounce, useMapCardSizes, useMapEaseTo } from '~/hooks'
import {
  STATUSES,
  TYPES,
  cn,
  fmtAddress,
  getPropertyTypeClassName,
} from '~/utils'

const algoliaClient = algoliasearch(
  'P9SMCQDPUI',
  '42a1fe78d9e75aeed6286378781b74ab'
)
const listingsIndex = algoliaClient.initIndex('listings_1')

export default function SearchListings({
  search,
  setIsOpen,
}: {
  search: string
  setIsOpen: (isOpen: boolean) => void
}) {
  const organization = useAtomValue(aOrganization)
  const user = useAtomValue(aUser)
  const mapRef = useMap()
  const mapEaseTo = useMapEaseTo(mapRef as any)
  const navigate = useNavigate()
  const setSelectedListing = useSetAtom(aSelectedListing)
  const [results, setResults] = useState<any[]>([])
  const [isLoading, setIsLoading] = useState(false)
  const mapCardSizes = useMapCardSizes()
  const [inputCleared, setInputCleared] = useState(false)
  const debouncedSearch = useDebounce(search, 200)
  const [statuses, setStatuses] = useState<string[]>([
    organization!.options.appraisersOnly ? 'sold' : 'active',
  ])

  const searchListings = useCallback(async () => {
    if (debouncedSearch.trim().length < 3) return

    // const getBoundingBox = () => {
    //   const bounds = mapRef.current!.getBounds()
    //   const ne = bounds.getNorthEast()
    //   const sw = bounds.getSouthWest()
    //   // return [[ne.lat, ne.lng, sw.lat, sw.lng]]
    //   return [[sw.lat, sw.lng, ne.lat, ne.lng]]
    // }
    setIsLoading(true)
    const statusesFilter = statuses
      .map((status) => `status:${status}`)
      .join(' OR ')
    const filters = `organization.slug:${organization!.slug} AND (${statusesFilter})`
    const { hits } = await listingsIndex.search(debouncedSearch.trim(), {
      hitsPerPage: 20,
      getRankingInfo: true,
      distinct: true,
      highlightPreTag: '<strong>',
      highlightPostTag: '</strong>',
      filters,
      // insideBoundingBox: !mapRef.current ? undefined : getBoundingBox(),
    })
    setResults(hits)
    setIsLoading(false)
  }, [debouncedSearch, statuses, organization, mapRef])

  useEffect(() => {
    setTimeout(() => setInputCleared(true), 200)
  }, [])

  useEffect(() => {
    if (!inputCleared) return
    if (!debouncedSearch) {
      setResults([])
    } else {
      searchListings()
    }
  }, [debouncedSearch, searchListings])

  const renderStatusBadges = () => {
    const statusOptions = organization!.options.appraisersOnly
      ? {
          sold: 'Sold',
          leased: 'Fully Leased',
          withdrawn: 'Withdrawn',
        }
      : STATUSES
    const publicStatuses = user
      ? Object.keys(statusOptions).filter(
          (s) => s !== 'deleted' && s !== 'draft' && s !== 'expired'
        )
      : ['active', 'pending']
    return (
      <div className="flex gap-1">
        {publicStatuses.map((status) => {
          return (
            <Badge
              key={status}
              variant={statuses.includes(status) ? 'default' : 'secondary'}
              className="cursor-pointer"
              onClick={(e) => {
                if (statuses.length === 1 && statuses.includes(status)) {
                  return
                }
                if (e.metaKey) {
                  setStatuses((prev) =>
                    prev.includes(status)
                      ? prev.filter((s) => s !== status)
                      : [...prev, status]
                  )
                } else {
                  setStatuses([status])
                }
              }}>
              {statusOptions[status]}
            </Badge>
          )
        })}
      </div>
    )
  }

  // const renderPropertyTypeBadges = () => {
  //   return (
  //     <div className="mt-2 flex flex-wrap gap-1">
  //       {PROPERTY_TYPES.map((propertyType) => {
  //         return (
  //           <Badge key={propertyType} variant="secondary" className="">
  //             {propertyType}
  //           </Badge>
  //         )
  //       })}
  //     </div>
  //   )
  // }

  return (
    <>
      <Command.Group>
        <div className="px-2 py-3">
          {renderStatusBadges()}
          {/* {renderPropertyTypeBadges()} */}
        </div>
      </Command.Group>
      <Command.Empty className="p-4 pt-1">
        {isLoading
          ? 'Loading results...'
          : debouncedSearch.length > 3 && results.length === 0
            ? 'No results found'
            : 'Start typing to search listings'}
      </Command.Empty>
      <Command.Group>
        {results.map((result) => {
          const matchesDescriptionHighlights =
            (result._highlightResult.meta?.description?.matchLevel &&
              result._highlightResult.meta?.description?.matchLevel !==
                'none') ||
            (result._highlightResult.meta?.highlight?.matchLevel &&
              result._highlightResult.meta?.highlight?.matchLevel !== 'none')
          return (
            <Command.Item
              key={result._id}
              value={result}
              className="listing-matched flex flex-col items-start"
              onSelect={() => {
                if (!mapRef.current) {
                  navigate(`/listings/${result.slug}`)
                } else {
                  setSelectedListing(result)
                  const searchParams = new URLSearchParams(
                    window.location.search
                  )
                  searchParams.set('lat', result.property.latitude.toString())
                  searchParams.set('lng', result.property.longitude.toString())
                  const z = mapRef.current!.getZoom()
                  searchParams.set('z', z < 16 ? '16' : z.toString())
                  navigate(`/${result.slug}?${searchParams}`)
                  setIsOpen(false)
                  if (mapRef) {
                    mapEaseTo({
                      center: [
                        result.property.longitude,
                        result.property.latitude,
                      ],
                      zoom: 16,
                      duration: 500,
                      padding: {
                        left: mapCardSizes.list + mapCardSizes.single,
                        bottom: 0,
                        right: 0,
                        top: 0,
                      },
                    })
                  }
                }
              }}>
              <div className="flex w-full items-start justify-between">
                <div>
                  <div className="mb-1 flex gap-2">
                    <p
                      className="text-base leading-tight"
                      dangerouslySetInnerHTML={{
                        __html:
                          result._highlightResult.name.matchLevel === 'none'
                            ? result.name
                            : result._highlightResult.name.value,
                      }}
                    />
                  </div>
                  <p
                    dangerouslySetInnerHTML={{
                      __html: fmtAddress({
                        address:
                          result._highlightResult.property.address?.value,
                        address2:
                          result._highlightResult.property.address2?.value,
                        city: result._highlightResult.property.city?.value,
                        stateAbbr:
                          result._highlightResult.property.stateAbbr?.value,
                        postalCode:
                          result._highlightResult.property.postalCode?.value,
                      }),
                    }}
                  />

                  {matchesDescriptionHighlights && (
                    <p className="italic">
                      Search found in description or highlights
                    </p>
                  )}
                  <div className="mt-1 flex gap-2">
                    <Badge
                      className={cn(
                        'px-1 text-xs leading-none',
                        result.type === 'sale' &&
                          'bg-[#0284c7] hover:bg-[#0284c7]',
                        result.type === 'lease' &&
                          'bg-[#c026d3] hover:bg-[#c026d3]',
                        result.type === 'saleLease' &&
                          'bg-[#4f46e5] hover:bg-[#4f46e5]'
                      )}>
                      {result.status === 'active'
                        ? TYPES[result.type]
                        : STATUSES[result.status]}
                    </Badge>
                    {result.propertyTypes.map(
                      (value: string, index: number) => {
                        return (
                          <Badge
                            key={index}
                            className={cn(
                              'text-xs',
                              getPropertyTypeClassName(value)
                            )}>
                            <span>{value}</span>
                          </Badge>
                        )
                      }
                    )}
                  </div>
                </div>
                {!!mapRef && (
                  <div className="">
                    <Button
                      asChild
                      variant="ghost"
                      size="map-sm"
                      className="absolute right-2 top-2 z-10 bg-white ring-primary ring-offset-transparent hover:bg-white hover:outline-none hover:ring-1 hover:ring-offset-1">
                      <Link
                        to={`/listings/${result.slug}`}
                        target="_blank"
                        onClick={(e) => {
                          e.stopPropagation()
                        }}>
                        <LucideExternalLink className="!h-4 !w-4" />
                      </Link>
                    </Button>
                  </div>
                )}
              </div>

              <span className="hidden">{result.objectID}</span>
              {/* {result._highlightResult.meta?.description?.matchLevel &&
              result._highlightResult.meta?.description?.matchLevel !==
                'none' && (
                <p
                  dangerouslySetInnerHTML={{
                    __html: result._highlightResult.meta?.description.value,
                  }}></p>
              )}
            {result._highlightResult.meta?.highlights?.matchLevel &&
              result._highlightResult.meta?.highlights?.matchLevel !==
                'none' && (
                <p
                  dangerouslySetInnerHTML={{
                    __html: result._highlightResult.meta?.highlights.value,
                  }}></p>
              )} */}
            </Command.Item>
          )
        })}
      </Command.Group>
    </>
  )
}
